import { formatAbTestsForAnalytics } from 'helpers/formatAbTestsForAnalytics';
import { getJsonFromStorage } from 'helpers/localStorageHelper';
import { AnyAction } from 'redux';

import { Analytics, AnalyticsUserProp } from 'services/analytics';

import { getMarketingValues } from 'utils/storeMarketingValues';
import { updateUserMeta } from 'utils/updateUserMeta';

import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

interface IChangeUserPassword {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
  onSuccess?: unknown;
  onFailed?: unknown;
}

export const getUserCountry = (onSuccess?: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USER_COUNTRY,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/user/country',
    },
    onSuccess,
    components: ['update_user'],
  };
  return action;
};

export const getUser = ({
  onFailed,
  onSuccess,
}: {
  onFailed?: unknown;
  onSuccess?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USER,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/user/me',
    },
    components: ['get_user'],
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: async (res: any) => {
      const abTests = getJsonFromStorage('abTests');

      Analytics.setUserId(res?.id);
      await updateUserMeta();

      const userData: AnalyticsUserProp[] = [{ key: 'is_premium', value: !!res?.subscription }];

      if (res?.email) userData.push({ key: 'email', value: res?.email });

      if (abTests?.length) {
        userData.push({
          key: 'ab_test',
          value: formatAbTestsForAnalytics(abTests),
        });
      }

      const marketingValues = getMarketingValues();
      if (marketingValues) {
        for (const [key, value] of Object.entries(marketingValues)) {
          userData.push({ key, value });
        }
      }

      Analytics.updateUser(userData);
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const setQueryUserId = (userId: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_QUERY_USER_ID,
    data: { userId: userId },
  };
  return action;
};

export const createAnonymousUser = (userId?: string, onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CREATE_ANONYMOUS_USER,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/user/create',
    },
    components: ['get_user'],
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
  };
  if (!!userId && action.payload) {
    action.payload.body = { id: userId };
  }
  return action;
};

export const createUserReveal = ({
  email,
  onSuccess,
  onFailed,
  sendFile,
  last_uploaded_filename,
}: {
  email: string;
  onSuccess?: unknown;
  onFailed?: unknown;
  sendFile?: boolean;
  last_uploaded_filename?: string;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CREATE_ANONYMOUS_USER,
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/user/reveal',
      body: {
        email,
        sendFile,
        last_uploaded_filename,
      },
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (error?: any) => {
      const errorMessage = error?.response?.data?.message || 'Create user error';
      if (typeof onFailed === 'function') onFailed(errorMessage);
    },
  };
  return action;
};

export const signUp = (
  email: string,
  password: string,
  onSuccess?: unknown,
  onFailed?: unknown,
  last_uploaded_filename?: string
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_UP,
    payload: {
      method: 'POST',
      endpoint: `api/v1/auth/signup-with-email`,
      body: {
        email,
        password,
        last_uploaded_filename,
      },
    },
    components: ['sign_up'],
    skipAuth: true,
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Create account error';
      if (typeof onFailed === 'function') onFailed(errorMessage);
    },
  };
  return action;
};

export const signIn = ({
  email,
  password,
  onSuccess,
  onFailed,
  rememberMe,
}: {
  email: string;
  password: string;
  onSuccess?: unknown;
  onFailed?: unknown;
  rememberMe?: boolean;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_IN,
    payload: {
      method: 'POST',
      endpoint: `api/v1/auth/login`,
      body: {
        email,
        password,
        rememberMe,
      },
    },
    components: ['sign_in'],
    skipAuth: true,
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Sign-in error';
      if (typeof onFailed === 'function') onFailed(errorMessage);
    },
  };
  return action;
};

export const signOut = (onSuccess?: unknown, onFailed?: unknown): AnyAction => {
  Analytics.sendEvent({ event: 'log_out_tap' });
  localStorage.removeItem('dataToConvert');
  localStorage.removeItem('dataToEdit');
  localStorage.removeItem('abTests');
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_OUT,
    payload: {
      method: 'POST',
      endpoint: `api/v1/auth/logout`,
      body: {},
    },
    components: ['sign_out'],
    skipAuth: true,
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Sign-out error';
      if (typeof onFailed === 'function') onFailed(errorMessage);
    },
  };
  return action;
};

export const changeUserPassword = ({
  currentPassword,
  newPassword,
  repeatPassword,
  onSuccess,
  onFailed,
}: IChangeUserPassword): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CHANGE_USER_PASSWORD,
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/user/change-password',
      body: {
        currentPassword,
        newPassword,
        repeatPassword,
      },
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    components: ['update_user'],
  };
  return action;
};

export const changeUserEmail = ({
  newEmail,
  onSuccess,
  onFailed,
}: {
  newEmail: string;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CHANGE_USER_EMAIL,
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/user/change-email',
      body: {
        newEmail,
      },
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    components: ['update_user'],
  };
  return action;
};

export const setUserEmail = (newEmail: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_USER_EMAIL,
    data: {
      newEmail,
    },
  };
  return action;
};

export const sendForgotPassword = (
  email: string,
  setSent: any = () => {},
  setError: any = () => {}
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SEND_FORGOT_PASSWORD,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/auth/recover-password-request',
      body: {
        email,
      },
    },
    components: ['password_forgot'],
    onSuccess: () => {
      if (setSent) setSent();
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Password reset error';
      setError(errorMessage);
    },
  };
  return action;
};

export const googleAuth = (
  token: string,
  onSuccess?: unknown,
  setError?: any,
  last_uploaded_filename?: string
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GOOGLE_AUTH,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/auth/google/sign-in',
      body: {
        token,
        last_uploaded_filename,
      },
    },
    components: ['google_auth'],
    skipAuth: true,
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Sign-in error';
      if (setError) setError(errorMessage);
    },
  };
  return action;
};

export const recoverPasswordPassword = (
  { password, repeatPassword, token }: { password: string; repeatPassword: string; token: string },
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SEND_FORGOT_PASSWORD,
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/auth/recover-password-confirmation',
      body: {
        password,
        repeatPassword,
        token,
      },
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
  };
  return action;
};

export const setPageView = (page: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_PAGE_VIEW,
    data: page,
  };
  return action;
};

export const getTrustPilotLink = (onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_TRUST_PILOT_LINK,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/trustpilot/invitation-link',
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const signupWithGoogle = ({
  clientRedirectPath,
  onSuccess,
  onFailed,
  last_uploaded_filename,
}: {
  clientRedirectPath: string;
  onSuccess?: unknown;
  onFailed?: unknown;
  last_uploaded_filename?: string;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_UP_WITH_GOOGLE,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/auth/google/get-link',
      body: {
        clientRedirectPath,
        last_uploaded_filename,
      },
      customHeaders: {
        'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
      },
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const signInWithGoogle = (onSuccess: any, onFailed: any, token: string) => {
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_IN_WITH_GOOGLE,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/auth/google/sign-in',
      body: {
        token,
      },
      customHeaders: {
        'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
        'Cross-Origin-Embedder-Policy': 'unsafe-none',
      },
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const loginByToken = (
  token: string,
  onSuccess?: (res: any) => void,
  onFailed?: (res: any) => void
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.LOGIN_BY_TOKEN,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/auth/by-email-token',
      body: { token },
    },
    onSuccess: (res: any) => {
      if (onSuccess) onSuccess(res);
    },
    onFailed: (res: any) => {
      if (onFailed) onFailed(res);
    },
  };

  return action;
};
