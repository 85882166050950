import { FC, ReactElement, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Upload } from 'antd';
import classNames from 'classnames';
/** Styles */
import { getFileFormatFromFile } from 'helpers/getFileFormatFromFile';
import { mergeImagesPageService } from 'helpers/services/mergeImageServiceList';
import { mergePageService } from 'helpers/services/mergePageService';
import { validateFile } from 'helpers/validation';
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';
import { MAX_FILES_ALLOWED } from 'pages/mergePdf';
import cross_btn from 'sections/upload-section/assets/cross.svg';
import { v4 as uuidv4 } from 'uuid';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { pushMergeDocument } from 'data/actions/documents';
import { mergeDocumentsListSelector } from 'data/selectors/documents';

import { convertToPdf } from 'utils/convertToPdf';
import { formatFileSize } from 'utils/formatFileSize';
import showToast from 'utils/toast';

import { PAGE_LINKS } from 'ts/constants/page-links';
import { IService } from 'ts/interfaces/services/service';

import s from './drag-n-drop-new-design.module.scss';
import { Container } from './styles';

export type FileItem = { file: File; thumbnail?: string | null };

const UploadButtonMerge: FC<{
  service?: IService | any;
  buttonText?: string;
  isFooter?: boolean;
  customButton?: ReactElement<any>;
  isDisabled?: boolean;
  isReversedText?: boolean;
  dataTestId?: string;
  insertAtIndex?: number;
  ignoreEvent?: boolean;
  placeForAnalytics?: string;
}> = ({
  service,
  isFooter,
  customButton,
  isDisabled,
  dataTestId,
  insertAtIndex,
  ignoreEvent,
  placeForAnalytics = '',
}: {
  service?: IService;
  buttonText?: string;
  isFooter?: boolean;
  customButton?: ReactElement<any>;
  isDisabled?: boolean;
  isReversedText?: boolean;
  dataTestId?: string;
  insertAtIndex?: number;
  ignoreEvent?: boolean;
  placeForAnalytics?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLocation = location.pathname.split('/')?.pop();

  const featureTapType =
    currentLocation === 'complete-merge' ? 'merge_add_pdf_tap' : 'features_tap';

  const mergeDocumentsList: any = useSelector(mergeDocumentsListSelector());

  const [disabled, setDisabled] = useState(false);
  const [countFiles, setCountFiles] = useState<number>(0);
  let sendNotification = false;
  const color = service?.mainColor || 'red';

  const availableFormats = useMemo(() => {
    return [mergePageService(t).availableFormats, mergeImagesPageService(t).availableFormats].join(
      ', '
    );
  }, [t]);

  const classesDragNDrop = {
    [s.dragNDrop]: true,
    [s.orange]: color === 'orange',
    [s.violet]: color === 'violet',
    [s.berry]: color === 'berry',
    [s.darkblue]: color === 'dark-blue',
    [s.berry]: color === 'red',
    [s.green]: color === 'green',
    [s.peach]: color === 'peach',
    [s.oceanblue]: color === 'ocean-blue',
  };

  const classesDropZone = {
    [s.dropZone]: true,
    [s.orange]: color === 'orange',
    [s.violet]: color === 'violet',
    [s.berry]: color === 'berry',
    [s.darkblue]: color === 'dark-blue',
    [s.green]: color === 'green',
    [s.peach]: color === 'peach',
    [s.oceanblue]: color === 'ocean-blue',
  };

  const handleBeforeUpload = (
    file: File & { uid: string },
    FileList: File[] & { uid: string }[]
  ) => {
    let isErrorMessage = validateFile(file, t, availableFormats);

    if (mergeDocumentsList?.length + FileList?.length > MAX_FILES_ALLOWED)
      isErrorMessage = t('global.upload_up_to', { count: MAX_FILES_ALLOWED });
    if (location?.pathname?.includes('/merge-pdf') || location?.pathname?.includes('/merge-img'))
      setCountFiles(FileList.length);

    if (isErrorMessage) {
      if (!sendNotification) {
        sendNotification = true;
        showToast('error', isErrorMessage, 5000, 'complete_merge_error_message');
        dispatch(sendAnalyticEvent({ event: 'complete_merge_error_message' }));
        dispatch(
          sendAnalyticEvent({
            event: 'file_upload_status',
            data: {
              status: 'fail',
              place: 'main',
              errorCode: (file?.size || 0) > 100 * 1024 * 1024 ? 'max-size' : 'wrong-format',
              size: formatFileSize(file?.size || 0),
              accurate_size: (file?.size || 0) / 1000000,
              fileCounter: 1,
              file_format: `.${file?.name?.split('.')?.pop()}`,
              is_validation_error: 'true',
            },
          })
        );
      }
      return false;
    }
    return true;
  };

  // disable drag & drop
  const handleOnDragOver = () => {
    if (!disabled) setDisabled(true);
  };

  // disable drag & drop
  const handleOnMouseOver = () => {
    if (disabled) setDisabled(false);
  };

  useEffect(() => {
    if (location?.pathname?.includes('/merge-pdf') || location?.pathname?.includes('/merge-img')) {
      if (countFiles && mergeDocumentsList?.length === countFiles) {
        navigate(PAGE_LINKS.COMPLETE_MERGE);
      }
    }
  }, [navigate, location, mergeDocumentsList, countFiles]);

  return (
    <Container
      onDragOver={() => handleOnDragOver()}
      onMouseOver={() => handleOnMouseOver()}
      onClick={() =>
        dispatch(
          sendAnalyticEvent({
            event: featureTapType,
            data: {
              features_name: service?.path?.replace('/', '') || '',
              method: 'click',
            },
          })
        )
      }
      data-testid={dataTestId || 'drag-n-drop-container'}
    >
      <Upload
        type="select"
        multiple
        maxCount={MAX_FILES_ALLOWED}
        disabled={isDisabled || disabled}
        accept={availableFormats}
        name="file"
        showUploadList={false}
        customRequest={(file: any) => {
          if (isFooter) dispatch(sendAnalyticEvent({ event: 'upload_ﬁle_footer_tap' }));
          if (file.file.type !== 'application/pdf') {
            return convertToPdf(file.file).then((newFile) => {
              dispatch(
                pushMergeDocument(
                  { file: newFile, thumbnail: null, hashId: uuidv4() },
                  {
                    index: insertAtIndex,
                    ignoreEvent,
                    originalFormat: getFileFormatFromFile(file.file) || '',
                  }
                )
              );
            });
          }
          dispatch(
            pushMergeDocument(
              { file: file.file, thumbnail: null, hashId: uuidv4() },
              { index: insertAtIndex, ignoreEvent }
            )
          );
        }}
        beforeUpload={handleBeforeUpload}
      >
        {customButton ? (
          customButton
        ) : (
          <>
            <div className={classNames(classesDragNDrop)}>
              <div className={classNames(classesDropZone)} onDragOver={(e) => e.preventDefault()}>
                <div className={s.content}>
                  <div className={s.uploadButton}>
                    <img src={cross_btn} alt="cross_btn" />
                  </div>
                  <span className="font-[700] small-desktop:block hidden">
                    {t('upload_section.widget.upload')}
                  </span>
                  <span className="font-[700] small-desktop:hidden block w-full mx-auto max-w-[400px]">
                    {t('upload_section.upload_from_device')}
                  </span>
                </div>
                <div
                  className="text-[13px] leading-[17px] text-[#F5F5F5] font-[500]
          text-center tablet:mb-6 mb-8 mx-8 mobile:mx-0 mobile:block hidden"
                >
                  {t('upload_section.widget.limit')}
                </div>
                <div
                  className="text-[16px] leading-[22px] text-[#F5F5F5] font-[600]
          text-center py-6 mobile:hidden block"
                >
                  {t('upload_section.click_to_upload')}
                </div>
              </div>
            </div>
          </>
        )}
      </Upload>
    </Container>
  );
};

export default UploadButtonMerge;
