import { Amplitude } from 'services/analytics/amplitude';
import { AnalyticsEvent } from 'services/analytics/events';
import { GtagAnalytics } from 'services/analytics/gtag';
import { FBPixel } from 'services/analytics/pixel';
import { getLocaleByPath } from 'utils/getLocaleByPath';

export const locales = [
  'fr',
  'it',
  'de',
  'es',
  'el',
  'ja',
  'pt',
  'pl',
  'tr',
  'ko',
  'vi',
  'fil',
  'nl',
  'ro',
  'id',
  'he',
  'ar',
];

const defaultPaths = [
  'app/login',
  'app/account',
  'app/editor',
  'app/choose-plan',
  'app/payment',
  'app/payment-success',
  'app/merge-pdf',
  'app/merge-img',
  'app/split-pdf',
  'app/compress-pdf',
  'app/sign-pdf',
  'app/password-protect-pdf',
  'app/sign-up',
  'app/cancel-coupon',
  'app/cancel-quiz',
  'app/cancel-success',
  'app/pdf-converter',
  'app/create-pdf',
  'app/delete-pages-from-pdf',
  'app/rotate-pdf',
  'app/edit-pdf',
  'app/fill-pdf',
  'app/form-ds11',
  'app/form-i9',
  'app/form-w9',
  'app/form-w2',
  'app/form-w3',
  'app/form-941',
  'app/form-1099',
  'app/form-1099-misc',
  'app/form-1099-nec',
  'app/form-1098-t',
  'app/form-1095-a',
  'app/form-1065',
  'app/form-2553',
  'app/form-1040',
  'app/form-w4',
  'app/form-ds82',
  'app/form-cms-1500',
  'app/form-433-d',
  'app/form-1040-x',
  'app/form-4506-t',
  'app/form-i-864',
  'app/form-ss-5',
  'app/cv-builder',
  'app/special-offer',
  'app/pdf-ocr',
  'app/image-to-text',
  'app/translate-pdf',
  'app/login',
  'app/account',
  'app/editor',
  'app/choose-plan',
  'app/payment',
  'app/payment-success',
  'app/merge-pdf',
  'app/merge-img',
  'app/split-pdf',
  'app/compress-pdf',
  'app/sign-pdf',
  'app/password-protect-pdf',
  'app/sign-up',
  'app/cancel-coupon',
  'app/cancel-quiz',
  'app/cancel-success',
  'app/pdf-converter',
  'app/create-pdf',
  'app/delete-pages-from-pdf',
  'app/rotate-pdf',
  'app/edit-pdf',
  'app/fill-pdf',
  'app/form-ds11',
  'app/form-i9',
  'app/form-w9',
  'app/form-w2',
  'app/form-w3',
  'app/form-941',
  'app/form-1099',
  'app/form-1099-misc',
  'app/form-1099-nec',
  'app/form-1098-t',
  'app/form-1095-a',
  'app/form-1065',
  'app/form-2553',
  'app/form-1040',
  'app/form-w4',
  'app/form-ds82',
  'app/form-cms-1500',
  'app/form-433-d',
  'app/form-1040-x',
  'app/form-4506-t',
  'app/form-i-864',
  'app/form-ss-5',
  'app/cv-builder',
  'app/special-offer',
  'app/pdf-ocr',
  'app/image-to-text',
  'app/complete-merge',
  'app/complete-split',
  'app/translate-pdf',
  'app/complete-merge',
];

export const Analytics = {
  sendEvent: async (event: AnalyticsEvent) => {
    const mappedEvent = {
      ...event,
      data: { ...event.data, env: process.env.REACT_APP_PUBLIC_APP_ENV }
    }

    Amplitude.track(mappedEvent);
    FBPixel.track(mappedEvent);
    GtagAnalytics.track(mappedEvent);
  },
  updateUser: (data: AnalyticsUserProp | AnalyticsUserProp[]) => {
    Amplitude.updateUser(data);
    FBPixel.updateUser(data);
    GtagAnalytics.updateUser(data);
  },
  setUserId: (userId: string) => {
    Amplitude.setUserId(userId);
    FBPixel.setUserId(userId);
    GtagAnalytics.setUserId(userId);
  },
  getCurrentPageName: () => {
    const path = window.location.pathname?.replace('v1/', '');
    const woSlash = path.replace('/', '');

    if (woSlash.includes('delete')) {
      return woSlash;
    }

    const locale = getLocaleByPath(path)

    const woLocales = locale ? woSlash.replace(`/${locale}`, '') : woSlash;

    // converters
    if (path.includes('-to-')) {
      return woLocales.replace(/-/g, '_');
    }

    if (defaultPaths.includes(woLocales)) {
      return woLocales;
    }

    if (window.location.pathname.includes('reset-password')) {
      return 'restore';
    }

    switch (window.location.pathname?.replace('v1/', '')) {
      case '/app':
      case '/app/v1':
        return 'app/main_page';
      case '/app/fr':
        return 'app/main_page';
      case '/app/it':
        return 'app/main_page';
      case '/app/de':
        return 'app/main_page';
      case '/app/es':
        return 'app/main_page';
      case '/app/el':
        return 'app/main_page';
      case '/app/pt':
        return 'app/main_page';
      case '/app/tr':
        return 'app/tr/main_page';
      case '/app/pl':
        return 'app/pl/main_page';
      case '/app/ko':
        return 'app/ko/main_page';
      case '/app/vi':
        return 'app/vi/main_page';
      case '/app/fil':
        return 'app/fil/main_page';
      case '/app/nl':
        return 'app/nl/main_page';
      case '/app/ro':
        return 'app/ro/main_page';
      case '/app/id':
        return 'app/id/main_page';
      case '/app/he':
        return 'app/he/main_page';
      case '/app/ar':
        return 'app/ar/main_page';
      default:
        return '404';
    }
  },
  getCurrentPageLocale: () => {
    const path = window.location.pathname?.replace('v1/', '');
    const woSlash = path.replace('/', '');

    if (woSlash.includes('delete')) {
      return woSlash;
    }

    const woLocales =
      locales.includes(woSlash.slice(4, 6)) &&
      woSlash.slice(4, 6).length === 2 &&
      woSlash[6] === '/'
        ? `app${woSlash.slice(6)}`
        : woSlash;

    // converters
    if (path.includes('-to-')) {
      return woSlash.replace(/-/g, '_');
    }

    if (defaultPaths.includes(woLocales)) {
      return woSlash;
    }

    if (window.location.pathname.includes('reset-password')) {
      return 'restore';
    }

    switch (window.location.pathname?.replace('v1/', '')) {
      case '/app':
      case '/app/v1':
        return 'app/main_page';
      case '/app/fr':
        return 'app/fr/main_page';
      case '/app/it':
        return 'app/it/main_page';
      case '/app/de':
        return 'app/de/main_page';
      case '/app/es':
        return 'app/es/main_page';
      case '/app/el':
        return 'app/el/main_page';
      case '/app/pt':
        return 'app/pt/main_page';
      case '/app/pl':
        return 'app/pl/main_page';
      case '/app/ko':
        return 'app/ko/main_page';
      case '/app/vi':
        return 'app/vi/main_page';
      case '/app/fil':
        return 'app/fil/main_page';
      case '/app/nl':
        return 'app/nl/main_page';
      case '/app/ro':
        return 'app/ro/main_page';
      case '/app/id':
        return 'app/id/main_page';
      case '/app/he':
        return 'app/he/main_page';
      case '/app/ar':
        return 'app/ar/main_page';
      default:
        return '404';
    }
  },
};

export interface AnalyticsService {
  init: () => void;
  track: (event: AnalyticsEvent) => void;
  updateUser: (data: AnalyticsUserProp | AnalyticsUserProp[]) => void;
  setUserId: (userId: string) => void;
}

export interface AnalyticsUserProp {
  key: string;
  value: string | number | boolean | string[];
}
