import { toggleModal } from 'data/actions/modals';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

type Params = {
  onClose?: unknown;
  onModalClose?: unknown;
}

const useCloseModalOnBack = ({ onClose, onModalClose }: Params = {}): void => {
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    } else {
      dispatch(toggleModal({ visible: false }));
      if (typeof onModalClose === 'function') onModalClose();
    }
  }, [onClose, onModalClose, dispatch]);

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      handleCloseModal();
    };
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleCloseModal]);
};

export default useCloseModalOnBack;
