import { FC, lazy, Suspense, useCallback, useEffect, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import * as pdfjs from 'pdfjs-dist';
/** Helpers */
import { formatAbTestsForAnalytics } from 'helpers/formatAbTestsForAnalytics';
import {
  converterServicesList,
  editorServicesList,
  formServicesList,
} from 'helpers/services/servicesList';
/** Hooks */
import useClarity from 'hooks/useClarity';
import useCustomerIO from 'hooks/useCustomerIO';
/** Styles */
import { useCustomerIOAttributes } from 'hooks/useCustomerIOAttributes';
import useResetPassword from 'hooks/useResetPassword';
/** Providers */
import { ABTestProvider, IS_MOBILE_KEY } from 'providers/abTests';
import GrowthBookCustomProvider from 'providers/growthBook';
import { LocaleLoadingProvider } from 'providers/localeProvider/localeLodingProvider';
import { PathLocaleProvider } from 'providers/pathLocaleProvider';
import { ProtectAuthRoutesProvider } from 'providers/protectAuthRoutesProvider';
import { RTLProvider } from 'providers/rtlProvider';
import 'styles/index.scss';

/** Components */
import ModalsController from 'components/modalsController';
import AppToaster from 'components/toast';

/** Services */
import { Analytics } from 'services/analytics';
import { Amplitude } from 'services/analytics/amplitude';
import { GtagAnalytics } from 'services/analytics/gtag';

/** Actions */
import { getPlans } from 'data/actions/subscriptions';
import { createAnonymousUser, getUser, getUserCountry } from 'data/actions/user';
/** Selectors */
import { abTestsSelector, getAbTestVariant } from 'data/selectors/abTests';
import { getLoadingSelector } from 'data/selectors/loading';
import { idSelector, queryUserIdSelector, userCountryCodeSelector } from 'data/selectors/user';

import { parseQuery } from 'utils/getQueryObject';
/** Use cases */
import { storeMarketingValues } from 'utils/storeMarketingValues';

/** i18n locales */
import { IS_ORGANIC_USER_LOCAL_STORAGE_KEY } from 'ts/constants/general';
/** Interfaces */
import { GrowthBookTestType, TestVariants } from 'ts/enums/growthbook';
import { IFormService, IService } from 'ts/interfaces/services/service';

import { DEFAULT_LANGUAGE, loadLocalesDayJs } from './i18n';
import './styles.css';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

/** Pages */
const NotFoundPage = lazy(() => import('pages/404'));
const PaymentSuccess = lazy(() => import('pages/paymentSuccess'));
const CheckoutPage = lazy(() => import('pages/checkout'));
const PricingPage = lazy(() => import('pages/pricing'));
const RedirectPage = lazy(() => import('pages/redirect'));
const ServicePage = lazy(() => import('pages/service'));
const LoginPage = lazy(() => import('pages/login-page'));
const SignUpPage = lazy(() => import('pages/sign-up-page'));
const PDFEditorPage = lazy(() => import('pages/editor'));
//const ImportExportEditorPage244 = lazy(() => import('pages/editor/import-export-editor-244'));
const CancelCouponPage = lazy(() => import('pages/cancelCouponPage'));
const CancelQuizPage = lazy(() => import('pages/cancelQuizPage'));
const FormServicePage = lazy(() => import('pages/formServicePage'));
const CancelSuccessPage = lazy(() => import('pages/cancel-success'));
const DeprecatedMobileMergePDFPage = lazy(() => import('pages/deprecatedMobileMergePdf'));
const MergePDFPage = lazy(() => import('pages/mergePdf'));
const SplitPDFPage = lazy(() => import('pages/splitPdf'));
const AccountPage = lazy(() => import('pages/account'));
const SettingsPage = lazy(() => import('pages/settings'));
const MainPage = lazy(() => import('pages/main'));
const EditorPageMobileEditor30 = lazy(() => import('pages/editor/mobile_editor_3_0'));
const AiSummarizer = lazy(() => import('pages/ai-summarizer'));

const RouterContainer: FC = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isLoading = useSelector(getLoadingSelector('get_user'));
  const abTests = useSelector(abTestsSelector);
  const countryCode = useSelector(userCountryCodeSelector);
  const userId = useSelector(idSelector);
  const queryUserId = useSelector(queryUserIdSelector);
  const newSalesVariant = useSelector(getAbTestVariant(GrowthBookTestType.ABCDE_NEW_SALES_2_4_2));

  const isMobile = localStorage.getItem(IS_MOBILE_KEY) === 'true';

  const importExportEditorVariant = useSelector(
    getAbTestVariant(GrowthBookTestType.ABCD_IMPORT_EXPORT_EDITOR_2_4_4)
  );
  const mobileEditorPageVariant = useSelector(
    getAbTestVariant(GrowthBookTestType.AB_MOBILE_EDITOR_3_0)
  );

  const isNewSplitVariant = useMemo(
    () => [TestVariants.B, TestVariants.C, TestVariants.D].includes(importExportEditorVariant),
    [importExportEditorVariant]
  );

  // TODO: test is stopped but we need to keep it because it will be new iteration of test
  // const importExportEditorPage = useMemo(
  //   () => ({
  //     [TestVariants.A]: <PDFEditorPage />,
  //     [TestVariants.B]: <ImportExportEditorPage244 />,
  //     [TestVariants.C]: <PDFEditorPage />,
  //     [TestVariants.D]: <PDFEditorPage />,
  //   }),
  //   []
  // );

  const mobileEditorPage = useMemo(
    () => ({
      [TestVariants.A]: <PDFEditorPage />,
      [TestVariants.B]: <EditorPageMobileEditor30 />,
    }),
    []
  );

  const germanyFreeTrial = useSelector(getAbTestVariant(GrowthBookTestType.AB_GERMANY_FREE_TRIAL));

  useResetPassword();
  useClarity();
  useCustomerIO();
  useCustomerIOAttributes();

  useEffect(() => {
    Analytics.updateUser({
      key: 'ab_test',
      value: formatAbTestsForAnalytics(abTests.tests),
    });
  }, [abTests?.tests]);

  const isCreatingUser = useRef(false);

  const handleFailGetUser = useCallback(() => {
    if (!isCreatingUser.current) {
      isCreatingUser.current = true;
      dispatch(createAnonymousUser(queryUserId));
    }
  }, [dispatch, queryUserId]);

  useEffect(() => {
    if (!userId) {
      dispatch(getUser({ onFailed: handleFailGetUser }));
    }
  }, [dispatch, handleFailGetUser, userId]);

  useEffect(() => {
    const hasClickId = window.location.search.includes('clickid=');
    if (!localStorage.getItem(IS_ORGANIC_USER_LOCAL_STORAGE_KEY)) {
      localStorage.setItem(IS_ORGANIC_USER_LOCAL_STORAGE_KEY, hasClickId ? 'false' : 'true');
    }
  }, []);

  useEffect(() => {
    if (!!userId) {
      dispatch(getUserCountry());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (!!countryCode)
      dispatch(getPlans({ userCountry: countryCode, newSalesVariant, germanyFreeTrial }));
  }, [dispatch, abTests, countryCode, newSalesVariant, germanyFreeTrial]);

  useEffect(() => {
    Amplitude.init();
    GtagAnalytics.init();
    storeMarketingValues();
    loadLocalesDayJs();
  }, []);

  useEffect(() => {
    Amplitude.updateUser({
      key: 'language',
      value: i18n.language || DEFAULT_LANGUAGE,
      ...parseQuery(window.location.href),
    });
  }, [i18n?.language, userId]);

  return (
    <Router>
      <RTLProvider>
        <ABTestProvider>
          <LocaleLoadingProvider>
            <PathLocaleProvider>
              <Suspense>
                <Routes>
                  <Route path="app">
                    <Route path=":language?">
                      <Route path={'redirect'} element={<RedirectPage />} />
                      <Route path={'login'} element={<LoginPage />} />
                      <Route path={'sign-up'} element={<SignUpPage />} />
                      <Route path={'404'} element={<NotFoundPage />} />
                      {!isLoading && <Route path="*" element={<Navigate to="404" replace />} />}
                      <Route
                        path={'account'}
                        element={<ProtectAuthRoutesProvider children={<AccountPage />} />}
                      />
                      <Route
                        path={'account-settings'}
                        element={<ProtectAuthRoutesProvider children={<SettingsPage />} />}
                      />

                      <Route path={`choose-plan`} element={<PricingPage />} />
                      <Route path={'cancel-coupon'} element={<CancelCouponPage />} />
                      <Route path={'cancel-quiz'} element={<CancelQuizPage />} />
                      <Route path={`payment`} element={<CheckoutPage />} />
                      <Route path={`payment-success`} element={<PaymentSuccess />} />
                      <Route path={`editor`} element={mobileEditorPage[mobileEditorPageVariant]} />
                      <Route path={`cancel-success`} element={<CancelSuccessPage />} />

                      <Route path={''} element={<MainPage />} />

                      {/* Start converter tools list */}
                      {converterServicesList(t).map((item: IService, index) => (
                        <Route
                          key={`route-${item.path}-${index + 1}`}
                          path={`${item.path.replace('/', '')}`}
                          element={<ServicePage service={item} />}
                        />
                      ))}
                      {/* End converter tools list */}

                      {/* Start editor tools list */}
                      {editorServicesList(t).map((item: IService, index) => (
                        <Route
                          key={`route-${item.path}-${index + 1}`}
                          path={`${item.path.replace('/', '')}`}
                          element={<ServicePage service={item} />}
                        />
                      ))}
                      {/* End editor tools list */}

                      {/* Start form tools list */}
                      {formServicesList(t).map((item: IFormService, index: number) => (
                        <Route
                          key={`route-${item.path}-${index + 1}`}
                          path={`${item.path.replace('/', '')}`}
                          element={<FormServicePage service={item} />}
                        />
                      ))}
                      {/* End form tools list */}

                      <Route
                        path={`complete-merge`}
                        element={isMobile ? <DeprecatedMobileMergePDFPage /> : <MergePDFPage />}
                      />

                      {isNewSplitVariant && (
                        <Route path={`complete-split`} element={<SplitPDFPage />} />
                      )}

                      <Route path={`ai-summarizer`} element={<AiSummarizer />} />

                      {/* <Route path="" element={<Navigate to="404" replace />} /> */}
                    </Route>
                  </Route>
                </Routes>
              </Suspense>
            </PathLocaleProvider>
            <ModalsController />
            <AppToaster />
          </LocaleLoadingProvider>
        </ABTestProvider>
      </RTLProvider>
    </Router>
  );
};

const App: FC = () => {
  return (
    <>
      <GrowthBookCustomProvider>
        <RouterContainer />
      </GrowthBookCustomProvider>
    </>
  );
};

export default App;
