import { FC } from 'react';
import { LanguageOption } from './LanguageOption';
import SearchSelect from './SearchSelect';
import { Language } from '../localesFull';
import { useTranslation } from 'react-i18next';

interface LanguageSectionProps {
  title: string;
  icon: string;
  selectedLang: Language;
  onSelectLang: (lang: Language) => void;
  defaultOptions: Language[];
  searchOptions: Language[];
  type: 'from' | 'to';
}

export const LanguageSection: FC<LanguageSectionProps> = ({
  title,
  icon,
  selectedLang,
  onSelectLang,
  defaultOptions,
  searchOptions,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 items-center text-[16px] font-[600] leading-5 mb-1">
        <img src={icon} alt="language icon" />
        {title}
      </div>
      
      {defaultOptions.map((item) => (
        <LanguageOption
          key={item.language}
          item={item}
          isSelected={selectedLang.code === item.code}
          onSelect={onSelectLang}
          analyticsType={type}
        />
      ))}
      
      <SearchSelect
        options={searchOptions}
        value={selectedLang}
        onChange={onSelectLang}
        placeholder={t('popups.translate_pdf_more_languages')}
        type={type}
      />
    </div>
  );
}; 