import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { calculateProgress, useAnimatedProgress } from 'hooks/useAnimatedProgress';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import useDisableScroll from 'hooks/useDisableScroll';

import { Analytics } from 'services/analytics';
import { FileUploadingModalViewTypes } from 'services/analytics/events';

import { updateDownloadProgress } from 'data/actions/documents';
import { uploadProgressSelector } from 'data/selectors/documents';
import { modalOptionsSelector } from 'data/selectors/modals';

import ModalLayout from '../baseModal/base';
import check from './assets/check.svg';
import loaderBg from './assets/loader-bg.svg';
import { ProgressBar } from './components/progress-bar';

const ProgressFileModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const downloadProgress = useSelector(uploadProgressSelector());
  const [stage, setStage] = useState(0);
  const animatedProgress = useAnimatedProgress(15);
  const progressValue = calculateProgress(animatedProgress, 0, 90);
  const [barValue, setBarValue] = useState(0);
  const modalOptions = useSelector(modalOptionsSelector);
  const secondaryType = modalOptions?.secondaryType || 'PNG';

  const eventsMap = useMemo(
    () =>
      new Map([
        [10, 'file_uploading_10'],
        [35, 'file_uploading_35'],
        [70, 'file_uploading_70'],
        [100, 'file_uploading_100'],
      ]),
    []
  );

  const getAnimationsUrl = useCallback((type: string) => {
    switch (type) {
      case 'JPG':
      case 'PNG':
      case 'WEBP':
      case 'JPEG':
        return 'https://lottie.host/a1476c96-f879-4352-8794-374498d84104/FytPQC6UfT.lottie';
      case 'EPUB':
        return 'https://lottie.host/93a27d5b-3b20-444a-ae9d-53b357ce00bd/m4GM39J7fy.lottie';
      case 'WORD':
      case 'DOC':
      case 'DOCX':
        return 'https://lottie.host/ac5cd74f-da90-477d-b5f6-eef323d4327d/ph748eBaqX.lottie';
      case 'PPT':
      case 'PPTX':
        return 'https://lottie.host/b57ad2ba-6e9d-4703-af48-580872d09308/2EqhEeo0Te.lottie';
      case 'XLS':
      case 'XLSX':
        return 'https://lottie.host/3f82e332-fb1f-4587-a620-40b243694212/sHXewU7ueR.lottie';
      default:
        return 'https://lottie.host/a1476c96-f879-4352-8794-374498d84104/FytPQC6UfT.lottie';
    }
  }, []);

  useDisableScroll();
  useCloseModalOnBack({ onModalClose: modalOptions?.onUserClose });

  useEffect(() => {
    if (progressValue > downloadProgress) {
      dispatch(updateDownloadProgress(progressValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, progressValue]);

  useEffect(() => {
    const newBarValue = downloadProgress.toFixed(0);

    if (newBarValue < 20) setBarValue(10);
    else if (newBarValue < 35) setBarValue(20);
    else if (newBarValue < 50) setBarValue(35);
    else if (newBarValue < 70) setBarValue(50);
    else if (newBarValue < 80) setBarValue(70);
    else if (newBarValue <= 90) setBarValue(93);
    else setBarValue(100);
  }, [downloadProgress]);

  useEffect(() => {
    return () => {
      dispatch(updateDownloadProgress(0));
    };
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => setStage(1), 1000);

    setTimeout(() => setStage(2), 2000);

    setTimeout(() => setStage(3), 3000);
  }, []);

  useEffect(() => {
    Analytics.sendEvent({
      event: 'file_uploading_modal_view',
      data: {
        type: FileUploadingModalViewTypes.convertFunnels,
        screen_config_name: 'advanced_loaders_animations',
      },
    });
  }, []);

  useEffect(() => {
    const event = eventsMap.get(barValue);
    if (event) {
      Analytics.sendEvent({ event });
    }
  }, [barValue, eventsMap]);

  return (
    <ModalLayout data-testid="converting-file-modal">
      <div className="flex flex-col items-center gap-8 tablet:w-[329px] w-full min-w-[calc(100%_-_32px)] py-10 px-8">
        <div className="w-full relative">
          <LazyLoadImage
            src={loaderBg}
            alt=""
            className="absolute top-0 left-0 w-full h-full scale-[1.1]"
          />
          <DotLottieReact
            src={getAnimationsUrl(secondaryType)}
            loop
            autoplay
            speed={0.7}
            className="scale-[2]"
          />
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col w-full items-center">
            <h2
              className="mb-[8px] text-[#1D1D1D] text-[25px] leading-[32.5px] font-bold"
              data-testid="converting-progress-modal-headline"
            >
              {t('popups.converting_with_progress_popup.headline')}
            </h2>
            <ProgressBar className="mt-0" progress={barValue} />
            <div className="mt-3 text-[#1D1D1D] text-[16px] leading-[21.6px] font-bold">
              {barValue}%
            </div>
          </div>

          <div className="text-[#1D1D1D] text-[16px] font-normal overflow-hidden flex flex-col gap-3 leading-[20.8px]">
            <div
              className="flex items-center gap-2"
              style={{
                transform: stage > 0 ? 'translateY(0)' : 'translateY(700%)',
                transition: 'transform 0.7s ease-in-out',
              }}
            >
              <img src={check} alt="check icon" />
              {t('popups.converting_with_progress_popup.stages.first')}
            </div>
            <div
              className="flex items-center gap-2"
              style={{
                transform: stage > 1 ? 'translateY(0)' : 'translateY(700%)',
                transition: 'transform 0.7s ease-in-out',
              }}
            >
              <img src={check} alt="check icon" />
              {t('popups.converting_with_progress_popup.stages.second')}
            </div>
            <div
              className="flex items-center gap-2"
              style={{
                transform: stage > 2 ? 'translateY(0)' : 'translateY(400%)',
                transition: 'transform 0.7s ease-in-out',
              }}
            >
              <img src={check} alt="check icon" />
              {t('popups.converting_with_progress_popup.stages.third')}
            </div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ProgressFileModal;
