export const isDev = window?.location?.host === 'ltdcfqn.pdfguru.com';
export const BASE_URL = isDev ? 'https://ltdcfqn.pdfguru.com' : 'https://pdfguru.com';

export const PAGE_LINKS = {
  SOCIAL_LinkedIn: 'https://www.linkedin.com/company/pdf-guru',
  SOCIAL_Facebook: 'https://www.facebook.com/pdfguru.converter/',
  SOCIAL_Twitter: 'https://x.com/pdfguru_com',
  SOCIAL_Pinterest: 'https://www.pinterest.com/pdfguruconverter/',

  // Start SEO pages
  TERMS: `${BASE_URL}/terms`,
  PRIVACY_POLICY: `${BASE_URL}/privacy-policy`,
  MONEY_BACK_POLICY: `${BASE_URL}/money-back-policy`,
  COOKIE_POLICY: `${BASE_URL}/cookie-policy`,
  FAQ_EU: `${BASE_URL}/faq-for-eu-residents`,
  ABOUT_US: `${BASE_URL}/about-us`,
  CONTACT_US: `${BASE_URL}/contact-us`,
  BLOG: `${BASE_URL}/blog`,
  EDIT_SIGN: `${BASE_URL}/edit-and-sign`,
  CONVERT: `${BASE_URL}/conversion-tools`,
  FORMS: `${BASE_URL}/forms`,
  MAIN_SEO: `${BASE_URL}/`,
  FAQ: `${BASE_URL}/faq`,
  SUBSCRIPTION_TERMS: `${BASE_URL}/subscription-terms`,
  // End SEO pages

  MAIN: '/app',
  HOME: '/app',
  LOGIN: '/app/login',
  EDIT_PDF: 'app/edit-pdf',
  COMPRESS_PDF: `/app/compress-pdf`,
  PDF_OCR: '/app/pdf-ocr',
  FILL_PDF: `/app/fill-pdf`,
  MERGE_PDF: '/app/merge-pdf',
  TRANSLATE_PDF: '/app/translate-pdf',
  MERGE_IMGS: `/app/merge-img`,
  SPLIT_PDF: `/app/split-pdf`,
  PROTECT_PDF: `${BASE_URL}/app/password-protect-pdf`,
  SIGNATURE_PDF: `/app/sign-pdf`,
  EDITOR: 'app/editor', // for testing skeleton
  DASHBOARD: `app/account`,
  SETTINGS: `app/settings`,
  DASHBOARD_SETTINGS: 'app/account-settings',
  CHOOSING_PLAN: '/app/choose-plan',
  PAYMENT: '/app/payment',
  PAYMENT_AI_SUMMARIZER: '/app/payment?feature=ai-summarizer',
  PAYMENT_SUCCESS: '/app/payment-success',
  SPECIAL_OFFER: '/app/special-offer',
  NOT_FOUND: '/app/404',
  SIGN_UP: '/app/sign-up',
  CANCEL_COUPON: '/app/cancel-coupon',
  CANCEL_QUIZ: 'app/cancel-quiz',
  CANCEL_SUCCESS: 'app/cancel-success',
  FORM_W9: `/app/form-w9`,
  FORM_I9: `/app/form-i9`,
  FORM_DS11: `/app/form-ds11`,
  FORM_1099MISC: `/app/form-1099-misc`,
  FORM_1099NEC: `/app/form-1099-nec`,
  FORM_1099: `/app/form-1099`,
  FORM_941: `/app/form-941`,
  FORM_W2: `/app/form-w2`,
  FORM_W3: `/app/form-w3`,
  FORM_1098T: `/app/form-1098-t`,
  FORM_1065: `/app/form-1065`,
  FORM_1095A: `/app/form-1095-a`,
  FORM_2553: `/app/form-2553`,
  FORM_1040: `/app/form-1040`,
  FORM_W4: `/app/form-w4`,
  FORM_DS82: `/app/form-ds82`,
  FORM_CMS1500: `/app/form-cms-1500`,
  FORM_433D: `/app/form-433-d`,
  FORM_1040X: `/app/form-1040-x`,
  FORM_4506T: `/app/form-4506-t`,
  FORM_I864: `/app/form-i-864`,
  FORM_SS5: `/app/form-ss-5`,
  PDF_TO_WORD: 'app/pdf-to-word', // for testing skeleton
  PDF_TO_PNG: `/app/pdf-to-png`,
  PDF_TO_JPG: `/app/pdf-to-jpg`,
  PDF_TO_EXCEL: `/app/pdf-to-excel`,
  PDF_TO_EPUB: `/app/pdf-to-epub`,
  PDF_TO_PPTX: `/app/pdf-to-pptx`,
  WORD_TO_PDF: `/app/word-to-pdf`,
  PNG_TO_PDF: '/app/png-to-pdf',
  JPG_TO_PDF: `/app/jpg-to-pdf`,
  EXCEL_TO_PDF: `/app/excel-to-pdf`,
  EPUB_TO_PDF: `/app/epub-to-pdf`,
  PPTX_TO_PDF: `/app/pptx-to-pdf`,
  PDF_CONVERTOR: `/app/pdf-converter`,
  IMAGE_TO_PDF: `/app/image-to-pdf`,
  IMAGE_TO_TEXT: '/app/image-to-text',
  CREATE_PDF: '/app/create-pdf',
  COMPLETE_MERGE: '/app/complete-merge',
  COMPLETE_SPLIT: '/app/complete-split',
  CV_BUILDER: 'app/cv-builder',
  SEO_LINKS: {
    SUBSCRIPTION_TERMS: `/subscription-terms`,
    TERMS: `/terms`,
    PRIVACY_POLICY: `/privacy-policy`,
    NOTICE: `/privacy-policy#topic_9`,
    MONEY_BACK_POLICY: `/money-back-policy`,
    COOKIE_POLICY: `/cookie-policy`,
    FAQ_EU: `/faq-for-eu-residents`,
  },
  AI_SUMMARIZER: '/app/ai-summarizer',
};
