import { memo, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import check from 'assets/img/icons/20px-new-design/check.svg';
import classNames from 'classnames';

import { getCompressedSize } from 'utils/getCompressedSize';

import { ECompressionLevel } from 'ts/enums/compressLevel';

type TTestId = 'high' | 'medium' | 'low';

export interface ICompressList {
  title: string;
  id: ECompressionLevel;
  testId: TTestId;
}

interface CompressionLevelsListProps {
  file: File;
  currentCompressionLevel: ECompressionLevel;
  handleSetCompressionLevel: (levelId: ECompressionLevel) => void;
}

interface CompressionLevelItemProps {
  item: ICompressList;
  fileSize: number;
  isSelected: boolean;
  onSelect: (level: ECompressionLevel) => void;
  t: (key: string) => string;
  testId: TTestId;
}

const CompressionLevelItem = memo(
  ({ item, fileSize, isSelected, onSelect, t, testId }: CompressionLevelItemProps) => {
    const handleClick = useCallback(() => {
      onSelect(item.id);
    }, [onSelect, item.id]);

    const { compressedSize, sizeUnit } = getCompressedSize(fileSize, item.id);

    return (
      <div
        data-testid={`${testId}-compression-level-item`}
        onClick={handleClick}
        className={classNames(
          'border-[2px] cursor-pointer border-solid bg-white flex flex-col w-full p-4 rounded-lg relative mobile:w-full transition-all duration-300',
          { 'border-[#5F30E2]': isSelected }
        )}
      >
        <div className="relative flex items-center justify-between">
          <div className="text-m capitalize font-bold">{item.id}</div>
          <div
            className={classNames(
              'absolute top-0 right-0 w-6 h-6 flex items-center justify-center rounded-full border-2 transition-all duration-300',
              {
                'bg-[#5F30E2] border-[#5F30E2] text-white': isSelected,
                'bg-white text-[#5F30E2]': !isSelected,
              }
            )}
          >
            <img src={check} alt="check" />
          </div>
        </div>

        <div className="flex items-center justify-between mt-2">
          <div className="text-sm whitespace-nowrap text-[#575757]">{item.title}</div>
        </div>

        <div className="flex items-center justify-center mt-2 bg-[#F5F5F5] rounded-md p-1 w-fit max-w-[200px]">
          <div className="text-base">
            {t('popups.processing_compress_popup.final_size')}{' '}
            <span className="font-bold">
              ~{compressedSize}
              {sizeUnit}
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export const CompressionLevelsList = ({
  file,
  currentCompressionLevel,
  handleSetCompressionLevel,
}: CompressionLevelsListProps) => {
  const { t } = useTranslation();
  const fileSizeBytes = file?.size ?? 0;

  const compressList: ICompressList[] = useMemo(
    () => [
      {
        title: t('popups.processing_compress_popup.compresses_levels.high'),
        id: ECompressionLevel.HIGH,
        testId: 'high',
      },
      {
        title: t('popups.processing_compress_popup.compresses_levels.medium'),
        id: ECompressionLevel.MEDIUM,
        testId: 'medium',
      },
      {
        title: t('popups.processing_compress_popup.compresses_levels.low'),
        id: ECompressionLevel.LOW,
        testId: 'low',
      },
    ],
    [t]
  );

  return (
    <div className="flex mobile:flex-row flex-col gap-4 w-full">
      {compressList.map((item) => (
        <CompressionLevelItem
          key={`modal-format-item-${item.id}`}
          item={item}
          fileSize={fileSizeBytes}
          isSelected={currentCompressionLevel === item.id}
          onSelect={handleSetCompressionLevel}
          t={t}
          testId={item.testId}
        />
      ))}
    </div>
  );
};
