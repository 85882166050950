export const MOBILE_SCREEN_SIZE_WIDTH = 630;
export const TABLET_SCREEN_SIZE_WIDTH = 1030;

export const ApplicationType = {
  PDF: 'application/pdf',
};

export const DefaultFileNames = {
  TRANSLATED: 'translated.pdf',
}

export type MainColors =
  | 'orange'
  | 'blue'
  | 'violet'
  | 'pink'
  | 'green'
  | 'red'
  | 'purple'
  | 'dark-blue'
  | 'gray'
  | 'berry'
  | 'peach'
  | 'ocean-blue';

export const FEATURE_IDS = {
  COMPRESS: 'compress-pdf',
  MERGE: 'merge-pdf',
  SPLIT: 'split-pdf',
  PROTECT: 'password-protect-pdf',
  UNLOCK: 'unlock-pdf',
  SIGNATURE: 'sign-pdf',
  IMAGE: 'jpg-to-pdf',
  MERGE_IMAGES: 'merge-images',
  PDF_OCR: 'pdf-ocr',
  FILL_PDF: 'fill-pdf',
  TRANSLATE: 'translate-pdf',
};

export const GET_RESULT_COUNT_RETRIES: number =
  Number(process.env.REACT_APP_GET_RESULT_COUNT_RETRIES) || 80;

export const IS_AUTO_TEST_LOCAL_STORAGE_KEY = 'isAutoTest';
export const IS_ORGANIC_USER_LOCAL_STORAGE_KEY = 'isOrganicUser';
