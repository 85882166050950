import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { Analytics } from 'services/analytics';

import arrow_bottom from '../assets/arrow_bottom.svg';
import radio_active from '../assets/radio_active.svg';
import { Language } from '../localesFull';

interface SearchSelectProps {
  options: Language[];
  value?: Language | null;
  onChange: (value: Language) => void;
  placeholder?: string;
  type: 'from' | 'to';
}

const SearchSelect: FC<SearchSelectProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Search language...',
  type,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const filteredOptions = options
    .filter((option) => option.language.toLowerCase().includes(search.toLowerCase()))
    .filter((option, index, self) => index === self.findIndex((t) => t.code === option.code));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.custom-select')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="custom-select relative">
      <div
        className={classNames(
          'h-[45px] p-4 border border-[#838383] rounded-xl flex items-center justify-between gap-2 cursor-pointer',
          {
            '!border-[#5F30E2] border-2': isOpen,
            '!border-[#5F30E2] border-2 bg-[#5F30E2] text-white':
              value && options.includes(value) && !isOpen,
          }
        )}
        onClick={() => {
          setIsOpen(!isOpen);
          !isOpen && Analytics.sendEvent({ event: 'click_other_language_tap' });
        }}
        data-testid={`choose-language-modal-search-select-${type}`}
      >
        {isOpen ? (
          <input
            type="text"
            className="w-full outline-none"
            placeholder={t('popups.translate_pdf_search_placeholder')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            autoFocus
          />
        ) : value && options.includes(value) ? (
          <div className="flex items-center gap-2">
            <img src={radio_active} alt="radio active" />
            <img className="w-6" src={value.link} alt="language icon" />
            <p>{value.language}</p>
          </div>
        ) : (
          placeholder
        )}
        <img src={arrow_bottom} alt="arrow bottom" />
      </div>

      {isOpen && (
        <div className="p-4 absolute bottom-full left-0 w-full border-2 border-[#5F30E2] bg-white rounded-xl shadow-lg mb-1 z-10">
          <div className="max-h-[400px] overflow-y-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((item) => (
                <div
                  key={item.code}
                  className={classNames(
                    'px-3 py-2 border-b border-[#E9E9E9] flex items-center gap-2 cursor-pointer hover:bg-[#F6F6F6] last:border-b-0'
                  )}
                  onClick={() => {
                    onChange(item);
                    setIsOpen(false);
                    setSearch('');
                    Analytics.sendEvent({
                      event: 'click_language_tap',
                      data: {
                        language: item.code,
                        type: type,
                        place: 'list',
                      },
                    });
                  }}
                  data-testid={`choose-language-modal-language-option-${item.language}`}
                >
                  <img className="w-6" src={item.link} alt="language icon" />
                  <p>{item.language}</p>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 text-[12px]">
                {t('popups.translate_pdf_nothing_found')}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSelect;
